import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';

import SurveyCreatorRenderComponent from './questionaire/src/SurveyCreatorComponent';


import FullPage from './FullPage';



const App = () => {
  /*     <div className='body'>
            
    <div class="container text-center">
        <div class="row">
            <div class="mt-5">
            </div>
        </div>
        <div class="row">
            <div class="ms-5"></div>
            <div class="col"><h1>Welcome to Lumos.</h1></div>
        </div>
        <div class="row">
            <div class="mt-2">
            </div>
        </div>
        <div class="row">

                <div className="card">
                    <div className="card-body">
                    <p class="card-text">Seasonal affective disorder (SAD) is a form of depression that occurs with certain seasons of the year, its peak during the months of January and February. There are many symptoms of SAD, including but not limited to sleep problems, irritability, and agitation. Those feeling the effects of SAD typically do not feel at ease in their day-to-day life. The main cause of SAD is reduced exposure to sunlight due to shorter and darker days, and one of the main concerns with SAD is individuals withdrawing from others. theHappyHacks mission is to ensure those struggling with SAD, are able keep in touch with those they care about, and access resources about how they can deal and cope with it. BLAHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH,HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHALB</p>
                    <SurveyCreatorRenderComponent></SurveyCreatorRenderComponent>
                    </div>
                </div>
            </div>
        </div>
    </div>*/

  return (
    <FullPage/>
  );
};

export default App;
